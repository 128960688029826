
import {Component, Prop, Vue} from 'vue-property-decorator';
import ApiService from '@/common/api.service';
import LineChart from '@/components/charts/LineChart.vue';
import { chartColors } from '@/components/charts';
import moment from 'moment';

@Component({
  components: { LineChart },
})
export default class AtiSUInfo extends Vue {
  @Prop({default: false})
  private isAtiSUInfoVisible!: boolean;
  @Prop({default: 0})
  private atiSuId!: number;
  private companyFullName: string = '';
  private companyATIId: string = '';
  private companyINN: string = '';
  private companyOGRN: string = '';
  private companyFirmType: string = '';
  private companyAddress: string = '';
  private companyCity: string = '';
  private companyWebSite: string = '';
  private companyScore: number = 0;
  private chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true,
          },
      }],
    },
  };
  private chartData: any = {};
  private contacts: any = [];

  private clearCompany() {
    this.companyFullName = '';
    this.companyATIId = '';
    this.companyINN = '';
    this.companyOGRN = '';
    this.companyCity = '';
    this.companyAddress = '';
    this.companyWebSite = '';
  }
  private async mounted() {
    this.clearCompany();
    let resp = await ApiService.query('/impact/verification/ati_su_summary',
        {ati_su_id: this.atiSuId});
    this.companyFullName = resp.data.full_name;
    this.companyATIId = resp.data.ati_id;
    this.companyINN = resp.data.inn;
    this.companyOGRN = resp.data.ogrn;
    this.companyFirmType = resp.data.firm_type;
    this.companyAddress = resp.data.address;
    this.companyCity = resp.data.city;
    this.companyWebSite = resp.data.web_site;
    this.companyScore = resp.data.score;
    resp = await ApiService.query('/impact/verification/ati_su_contacts',
        {ati_su_id: this.atiSuId});
    this.contacts = resp.data;
    await this.drawGraph();
  }

  private closeAtiSuInfo() {
    this.$emit('exit', true);
  }

  private async drawGraph() {
    const resp = await ApiService.query('/impact/verification/ati_su_activity',
        {ati_su_id: this.atiSuId});
    const stats = resp.data.statisticItems;
    let tmp: any = [];
    const datasets: any = [];
    const colorsAndNames: any = {
      other: {color: chartColors.blue, label: 'Посещение'},
      add_truck: {color: chartColors.darkBrown, label: 'Добавление машины'},
      truck_search: {color: chartColors.yellow, label: 'Поиск машин'},
      add_load: {color: chartColors.purple, label: 'Добавление груза'},
      load_search: {color: chartColors.red, label: 'Поиск груза'},
      forum: {color: chartColors.green, label: 'Чтение форумов'},
    };
    Object.keys(colorsAndNames).forEach((item) => {
      if (stats[item] && stats[item].stats) {
        tmp = tmp.concat(Object.keys(stats[item].stats));
      }
    });
    const labels = [...new Set(tmp)].sort();
    ['other', 'add_truck', 'truck_search', 'add_load', 'load_search', 'forum'].forEach((item: any) => {
      const tmp: any = [];
      labels.forEach((statDate: any, i: number) => {
        if (stats[item] && stats[item].stats) {
          if (stats[item].stats[statDate]) {
            tmp.push(stats[item].stats[statDate]);
          } else {
            tmp.push(0);
          }
        }
      });
      datasets.push({
        data: tmp,
        label: colorsAndNames[item].label,
        fill: false,
        lineTension: 0,
        backgroundColor: colorsAndNames[item].color,
        borderColor: colorsAndNames[item].color,
      });
    });
    moment.locale(this.$i18n.locale);
    this.chartData = { labels: labels.map((item: any) => moment(item).format('MMM.YYYY')), datasets };
  }
}
