
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InfoTooltip extends Vue {
  @Prop({ default: null })
  private data!: any;
  private closeToolTip() {
    this.$emit('close-tooltip', this.data.id);
  }

  get photoWidth() {
    return {width: `${this.data.width}px`};
  }
}
