
import { Component, Prop, Vue } from 'vue-property-decorator';
import Spinner from '@/components/Spinner.vue';

@Component({
  components: { Spinner },
})
export default class InactiveResult extends Vue {
  @Prop({ default: '' })
  private label!: string;

  @Prop({ default: false })
  private loader!: boolean;

  @Prop({ default: false })
  private bigSize!: boolean;
}
