
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AtiSUInfo from '@/components/Verification/AtiSUInfo.vue';

@Component({
  components: {AtiSUInfo},
  computed: mapGetters(['driverData', 'autoData', 'osagoData', 'osagoDataIsLoading', 'reputationData',
    'participationData']),
})
export default class VerificationResults extends Vue {
  @Prop()
  private driverDataIsLoading!: any;

  @Prop({ default: () => [] })
  private emptyFieldsTransport!: any;

  @Prop({ default: () => [] })
  private emptyDriverLicense!: any;

  @Prop({ default: () => [] })
  private emptyFieldsPassport!: any;

  @Prop({ default: () => [] })
  private emptyFieldsFssp!: any;

  @Prop({ default: () => [] })
  private emptyDriverPhoto!: any;

  @Prop({ default: () => [] })
  private formPassed!: any;

  @Prop({ default: false })
  private allFieldsNotEmpty!: boolean;

  @Prop({ default: {} })
  private transportFormData!: any;

  @Prop({ default: false })
  private someDataLoading!: boolean;

  @Prop({ default: false })
  private dataRecieved!: boolean;

  @Prop({ default: false })
  private riskIsLoading!: boolean;

  private osagoData!: any;

  @Prop()
  private emptyFieldsOsago!: any;

  @Prop()
  private emptyFieldsPhone!: any;

  private driverData!: any;

  private autoData!: any;

  private reputationData!: any;
  private participationData!: any;

  private isLoading: boolean = false;

  private transportFormDataEmpty: any = [];

  private formFillProgress: number = 0;
  private modalAtiSuId: number = 0;

  @Watch('transportFormData', {deep: true})
  private handleFormFill() {
    const formFields = Object.keys(this.transportFormData).length;
    const filledFields = Object.keys(this.transportFormData)
        .filter((field: any) => this.transportFormData[field]).length;
    this.formFillProgress = Math.round(100 * filledFields / formFields);
    this.transportFormNullFields();
  }

  get riskScore() {
    let score = 'low';
    if (!this.driverData.phone.confirmed || (this.driverData.fssp.data && this.driverData.fssp.data.length) ||
        (this.driverData.trust_person.found && this.driverData.trust_person.resultClass === 'warning') ||
        (this.driverData.passport_complex.score > 0 && this.driverData.passport_complex.score < 100)) {
      score = 'mid';
    }
    if (this.autoData.data && this.autoData.data.length || this.driverData.passport_complex.score === 0 ||
      !(typeof this.driverData.driver.valid === 'undefined' || this.driverData.driver.valid)
        || this.driverData.check_face.found ||
        (this.driverData.trust_person.found && this.driverData.trust_person.resultClass === 'alert')) {
      score = 'high';
    }
    if (this.autoData.data) {
       if (this.autoData.resultClass === 'alert') {
         score = 'high';
       } else if (score === 'low' && this.autoData.dangerLvl === 'warning') {
         score = 'mid';
       }
    }
    return score;
  }

  get atiScore() {
    let result = 'success';
    if (this.autoData.data) {
      this.autoData.data.map((row: any, index: number) => {
        row.externalId.map((row: any, index: number) => {
          if (this.autoData.atiId.toString() === row) {
            result = 'alert';
          }
        });
      });
    }
    return result;
  }

  get driverLicenseInfo() {
    const licenseFail = [
      this.driverData.driver.error ? ('Ошибка' + this.driverData.driver.msg ? (': ' + this.driverData.driver.msg) : '')
          : '',
      this.driverData.driver.wanted ? 'В розыске' : '',
      this.driverData.driver.categories ? '' : 'Не найдено',
      this.driverData.driver.status === 'Действует' ? '' : (
        this.driverData.driver.status === 'Недействителен' ? 'Недействительно' : this.driverData.driver.status),
      this.driverData.driver.decisionList && this.driverData.driver.decisionList.filter(
        (elem: any) => elem.state && elem.state.toLowerCase().includes('лишени')).length ?
        'Лишение права управления ТС' : '',
      this.driverData.driver.drivingLicenseExpired ? 'Недействительно' : '',
    ].filter((item) => item);
    if (!licenseFail.length) {
      return [false, `Действительно`];
    } else {
      return [true, licenseFail.join('; ')];
    }
  }

  private transportFormNullFields() {
    this.transportFormDataEmpty = [];
    Object.keys(this.transportFormData).map((key) => {
      if (!this.transportFormData[key] || this.transportFormData[key].toString().trim() === '') {
        this.transportFormDataEmpty.push(this.$i18n.t('strings.' + key));
      }
    });
  }

  private openAtiSUInfo(atiSuId: number) {
    this.modalAtiSuId = atiSuId;
  }

  private closeAtiSUInfo() {
    this.modalAtiSuId = 0;
  }
}
