
import {Component, Vue, Watch} from 'vue-property-decorator';
import {i18n} from '@/translations';
import FormInput from '@/components/Verification/FormInput.vue';
import Spinner from '@/components/Spinner.vue';
import {mapGetters} from 'vuex';
import {
  FETCH_AUTO_VERIFICATION, FETCH_DRIVER_VERIFICATION, FETCH_OSAGO_VERIFICATION, FETCH_REPUTATION_VERIFICATION,
  FETCH_VERIFICATION_TICKET, FETCH_VERIFICATION_TICKETS, UPLOAD_PASSPORT_SCAN, FETCH_ATI_SU_ID,
  FETCH_PARTICIPATION_VERIFICATION,
} from '@/store/actions.type';
import VerificationResults from '@/components/Verification/VerificationResults.vue';
import InactiveResult from '@/components/Verification/InactiveResult.vue';
import VerificationSign from '@/components/Verification/VerificationSign.vue';
import InfoTooltip from '@/components/Verification/InfoTooltip.vue';
import VerificationHistory from '@/components/Verification/VerificationHistory.vue';
import {
  CLEAR_CHECK_FACE, DROP_PASSPORT_DATA,
  CHANGE_TRANSPORT_FORM, CLEAR_EMPTY_FIELDS, CLEAR_VERIFICATION_HISTORY,
} from '@/store/mutations.type';
import vuescroll from 'vuescroll';
import { convertDate, getFileFromBase64 } from '@/common/helpers';
import IncidentView from '@/components/IncidentView.vue';
import {
  CHECK_RISK, CHECK_FSSP, CHECK_OSAGO, CHECK_DRIVER, CHECK_PASSPORT_COMPLEX, CHECK_PHONE, CHECK_TRUST_PERSON,
  CHECK_FACE, CHECK_PARTICIPANT, CHECK_REPUTATION, FIELD_PHOTO, FIELD_LAST_NAME, FIELD_FIRST_NAME,
  FIELD_MID_NAME, FIELD_PHONE, FIELD_PASSPORT, FIELD_PASSPORT_DATE, FIELD_BIRTHDATE,
  FIELD_TRACTOR_LICENSE_PLATE, FIELD_TRAILER_LICENSE_PLATE, FIELD_TRACTOR_VIN,
  FIELD_TRAILER_VIN, FIELD_DRIVING_LICENSE, FIELD_DRIVING_LICENSE_DATE, FIELD_OGRN,
  FIELD_INN, FIELD_ATI_SU_ID,
} from '@/store/verification.type';
import AtiSUInfo from '@/components/Verification/AtiSUInfo.vue';
import ApiService from '@/common/api.service';

@Component({
  components: {
    VerificationSign, InactiveResult, VerificationResults, Spinner, AtiSUInfo,
    FormInput, vuescroll, IncidentView, InfoTooltip, VerificationHistory,
  },
  computed: mapGetters(['errors', 'isLoading', 'autoData', 'driverDataIsLoading', 'driverData',
    'osagoDataIsLoading', 'osagoData', 'risk', 'riskIsLoading', 'currentUser', 'ticketError',
    'recognizeDriverData', 'transportFormData', 'transportFormSubmitted', 'reputationData',
    'emptyDriverLicense', 'emptyFieldsPhone', 'emptyFieldsPassport', 'reputationIsLoading',
    'emptyFieldsFssp', 'emptyFieldsOsago', 'emptyFieldsTransport', 'currentUser', 'participationData',
    'participationIsLoading', 'ticketsCount']),
})
export default class VerificationPage extends Vue {
  public currentUser!: any;
  private ticketsCount!: number;
  private VERIFICATION_TYPES = {CHECK_RISK, CHECK_FSSP, CHECK_OSAGO, CHECK_DRIVER, CHECK_PASSPORT_COMPLEX, CHECK_PHONE,
    CHECK_TRUST_PERSON, CHECK_FACE, CHECK_REPUTATION, CHECK_PARTICIPANT};
  private showHistory = false;

  private photoPreviewData: any = '';

  private formShow: boolean = true;

  private passportScan: any = '';

  private passportError: boolean = false;

  private passportStatus = i18n.t('uploaded');

  private localError: any = '';

  private transportFormData!: any;
  private transportFormSubmitted!: boolean;

  private osagoSubmitted: boolean = false;

  private emptyDriverLicense!: string[];

  private emptyFieldsPhone!: string[];

  private emptyFieldsPassport!: string[];

  private emptyFieldsFssp!: string[];

  private emptyFieldsOsago!: string[];

  private emptyFieldsTransport!: string[];

  private allFieldsNotEmpty: boolean = false;

  private ticketError!: any;

  private showTicketError: boolean = false;

  private driverDataIsLoading!: any;

  private recognizeDriverData!: any;

  private reputationIsLoading!: boolean;
  private participationIsLoading!: boolean;

  private formFillProgress: number = 0;

  private driverData!: any;

  private autoData!: any;
  private reputationData!: any;
  private participationData!: any;

  private dataRecieved: boolean = false;

  private trustPersonSuccess = false;

  private showIncidentView = false;

  private currentToolTip: any = null;
  private currentPhotoToolTip: any = null;
  private modalAtiSuId: number = 0;
  private foreignDocuments: boolean = false;

  private scrollOptions = {
    rail: {
      opacity: '0.1',
      background: '#000000',
      size: '4px',
      gutterOfSide: '1px',
      gutterOfEnds: '8px',
    },
    bar: {
      background: '#ff2d55',
      keepShow: true,
      specifyBorderRadius: '4px',
      size: '4px',
      minSize: 0.1,
    },
  };

  private showInfoToolTip(incident: any) {
    const elements: any = this.$refs['infoButton' + incident.id];
    const incidentWithCoords: any = {
      ...incident,
      top: elements[0].getBoundingClientRect().top + document.documentElement.scrollTop,
      left: elements[0].getBoundingClientRect().left + (elements[0].clientWidth / 2),
    };
    this.currentToolTip = incidentWithCoords;
  }

  private showPhotoToolTip(incident: any) {
    const elements: any = this.$refs['photoRow' + incident.id];
    const incidentWithCoords: any = {
      ...incident,
      top: elements[0].getBoundingClientRect().top + document.documentElement.scrollTop,
      left: elements[0].getBoundingClientRect().left + (elements[0].clientWidth / 2),
      width: elements[0].naturalWidth,
      height: elements[0].naturalHeight,
      photo: true,
    };
    this.currentPhotoToolTip = incidentWithCoords;
  }

  private closeInfoToolTip() {
    this.currentToolTip = null;
  }

  private closePhotoToolTip() {
    this.currentPhotoToolTip = null;
  }

  private dropPassport() {
    this.passportError = false;
    this.passportStatus = this.$i18n.t('uploaded');
    this.passportScan = '';
    this.$store.commit(DROP_PASSPORT_DATA);
  }

  private clearFormFields() {
    this.$store.commit(CHANGE_TRANSPORT_FORM, false);
    Object.keys(this.transportFormData).map((tKey) => {
      this.transportFormData[tKey] = '';
    });
    this.passportScan = '';
    (this.$refs.uploadphoto as HTMLInputElement).value = '';
    this.passportStatus = this.$i18n.t('uploaded');
  }

  get fieldsTrustPerson() {
    if (!this.driverData.trust_person.data) {
      return [];
    }
    return ['c1', 'c2', 'c3', 'c4', 'c5', 'c6'].map((field) => {
      return {
        label: this.driverData.trust_person.data[`${field}Name`], key: field,
        ...this.getColorClass(this.driverData.trust_person.data[field], field),
      };
    });
  }

  private getColorClass(trustColor: string, field: string) {
    let myClass = 'success'; // warning, alert
    if (trustColor === 'RED' || trustColor === 'HIGH_RED') {
      if (field === 'c1' || field === 'c2' || field === 'c3') {  // розыск, группы риска
        myClass = 'alert';
      } else {
        myClass = 'warning';
      }
    }
    switch (trustColor) {
      case 'GREEN':
        return {class: myClass, text: this.$i18n.t('strings.incidentsNotFound')};
      case 'RED':
        return {class: myClass, text: this.$i18n.t('strings.incidentsFoundFew')};
      default:
        return {class: myClass, text: this.$i18n.t('strings.incidentsFoundMany')};
    }
  }

  get formattedTrustPerson() {
    return this.fieldsTrustPerson ? [this.fieldsTrustPerson.map(() => '')] : [];
  }

  get strokeDashoffset() {
    return 16.4 * 2 * Math.PI - (this.formFillProgress / 100) * 16.4 * 2 * Math.PI;
  }

  get driverDataIsLoadingSome() {
    let isLoading = false;
    Object.keys(this.driverDataIsLoading).map((dataType) => {
      if (this.driverDataIsLoading[dataType]) {
        isLoading = true;
      }
    });
    return isLoading;
  }

  get emptyDriverPhoto() {
    return !this.transportFormData.photo
        || this.transportFormData.photo && !Object.keys(this.driverData.check_face).length
        && !this.driverDataIsLoading.check_face;
  }

  get driverLicenseInfo() {
    const licenseFail = [
      this.driverData.driver.wanted ? 'В розыске' : '',
      this.driverData.driver.categories ? '' : 'Не найдено',
      this.driverData.driver.status === 'Действует' ? '' : (
        this.driverData.driver.status === 'Недействителен' ? 'Недействительно' : this.driverData.driver.status),
      this.driverData.driver.decisionList && this.driverData.driver.decisionList.filter(
          (elem: any) => elem.state && elem.state.toLowerCase().includes('лишени')).length ?
          'Лишение права управления ТС' : '',
      this.driverData.driver.drivingLicenseExpired ? 'Недействительно' : '',
    ].filter((item) => item);
    if (!licenseFail.length) {
      return [false, `Действительно`];
    } else {
      return [true, licenseFail.join('; ')];
    }
  }

  get driverLicenseData() {
    return this.driverData.driver.decisionList ? this.driverData.driver.decisionList.filter(
        (elem: any) =>
            elem.state && elem.state.toLowerCase().includes('лишени')) : [];
  }

  get formattedCheckFace() {
    if (!this.driverData.check_face.data) {
      return [];
    }
    return this.driverData.check_face.data
        .map((row: any, index: number) => {
          row.index = index + 1;
          row.score = `${Math.round((row.score + Number.EPSILON) * 100)}%`;
          row.event_date = row.event_date ? row.event_date : '';
          row.description = row.description ? row.description : '';
          return row;
        });
  }

  get formattedAutoData() {
    return this.autoData.data
        .map((row: any, index: number) => {
          row.index = index + 1;
          row.ogrn = row.ogrn ? row.ogrn.join(' ') : '';
          return row;
        });
  }

  get formatFssp() {
    return this.driverData.fssp.data ? this.driverData.fssp.data
        .map((row: any, index: number) => {
          row.index = index + 1;
          row.debtorFm = row.debtor ?
              [row.debtor.name, row.debtor.birthDate, row.debtor.address].join(', ') : '';
          row.executiveDocumentFm = row.executiveDocument ?
              [row.executiveDocument.document, row.executiveDocument.issuer].join(', ') : '';
          row.reasonFm = (row.executiveDocument && row.reason) ?
              [row.reason.article, row.reason.part, row.reason.paragraph]
                  .filter((item) => item).join(', ') : '';
          row.departmentFm = row.department ? [row.department.name, row.department.address].join(', ') : '';
          row.executorFm = row.executor ?
              [row.executor.name, row.executor.contacts ? row.executor.contacts.join(',') : '']
                  .filter((item) => item).join(', ') : '';
          return row;
        }) : [];
  }

  @Watch('transportFormData', {deep: true})
  private handleFormFill() {
    const formFields = Object.keys(this.transportFormData).length;
    const filledFields = Object.keys(this.transportFormData)
        .filter((field: any) => this.transportFormData[field]).length;
    this.formFillProgress = Math.round(100 * filledFields / formFields);
  }

  get mandatoryFields() {
    const fields: any = {};
    const intToStringName: any = {
      [FIELD_PHOTO]: 'photo',
      [FIELD_LAST_NAME]: 'lastName',
      [FIELD_FIRST_NAME]: 'firstName',
      [FIELD_MID_NAME]: 'midName',
      [FIELD_PHONE]: 'phone',
      [FIELD_PASSPORT]: 'passportNumber',
      [FIELD_PASSPORT_DATE]: 'passportDate',
      [FIELD_BIRTHDATE]: 'birthDate',
      [FIELD_TRACTOR_LICENSE_PLATE]: 'autoGos',
      [FIELD_TRAILER_LICENSE_PLATE]: 'trailerGos',
      [FIELD_TRACTOR_VIN]: 'autoVin',
      [FIELD_TRAILER_VIN]: 'trailerVin',
      [FIELD_DRIVING_LICENSE]: 'drivingLicenseNumber',
      [FIELD_DRIVING_LICENSE_DATE]: 'drivingLicenseDate',
      [FIELD_OGRN]: 'inn',
      [FIELD_INN]: 'ogrn',
      [FIELD_ATI_SU_ID]: 'externalId',
    };
    if (this.foreignDocuments) {
      return {};
    }
    Object.keys(intToStringName).forEach((item: any) => {
      if (this.currentUser.requiredVettingFields & 2 ** item) {
        fields[intToStringName[item]] = true;
      }
    });
    return fields;
  }

  get isMandatoryEmpty() {
    return !!Object.keys(this.transportFormData)
      .filter((field: any) => {
        if (this.mandatoryFields[field] && field === 'photo') {
          return !this.photoPreviewData.length;
        }
        return this.mandatoryFields[field] && !this.transportFormData[field].length;
      }).length;
  }

  private async onSubmitAuto() {
    if (this.isMandatoryEmpty) {
      return;
    }
    let emptyQuery = true;
    this.$store.commit(CLEAR_EMPTY_FIELDS);
    this.$store.commit(CLEAR_VERIFICATION_HISTORY);
    this.localError = '';
    const transportMandatoryFields = ['autoGos', 'autoVin', 'trailerGos', 'trailerVin', 'ogrn', 'inn', 'externalId'];
    Object.keys(this.transportFormData).map((key) => {
      if (this.transportFormData[key as string] &&
          this.transportFormData[key as string].toLocaleString().length) {
        emptyQuery = false;
      }
      if (transportMandatoryFields.includes(key) &&
          !String(this.transportFormData[key]).length) {
        this.emptyFieldsTransport.push(key);
      }
      if (['lastName', 'firstName', 'midName', 'birthDate', 'passportNumber', 'passportDate'].includes(key) &&
          !String(this.transportFormData[key]).length) {
        this.emptyFieldsPassport.push(key);
      }
      if (['lastName', 'firstName', 'midName', 'birthDate'].includes(key) &&
          !String(this.transportFormData[key]).length) {
        this.emptyFieldsFssp.push(key);
      }
      if (['lastName', 'firstName', 'birthDate', 'passportNumber', 'phone'].includes(key) &&
          !String(this.transportFormData[key]).length) {
        this.emptyFieldsPhone.push(key);
      }
      if (['drivingLicenseDate', 'drivingLicenseNumber'].includes(key) &&
          !String(this.transportFormData[key]).length) {
        this.emptyDriverLicense.push(key);
      }
      if (['autoGos', 'autoVin'].includes(key) &&
          !String(this.transportFormData[key]).length) {
        this.emptyFieldsOsago.push(key);
      }
    });
    if (this.transportFormData.drivingLicenseNumber &&
        (this.transportFormData.autoGos || this.transportFormData.autoVin)) {
      this.osagoSubmitted = true;
    } else {
      this.osagoSubmitted = false;
    }
    if (emptyQuery) {
      this.localError = this.$i18n.t('errors.fillOneField');
      return;
    }
    this.allFieldsNotEmpty = false;
    this.$store.commit(CHANGE_TRANSPORT_FORM, true);
    if (!this.formPassed.length) {
      return;
    }
    await this.$store.dispatch(FETCH_VERIFICATION_TICKET, this.transportFormData);
    if (this.ticketError) {
      this.showTicketError = true;
      return;
    }
    await Promise.all([
      this.$store.dispatch(FETCH_AUTO_VERIFICATION, this.transportFormData),
      this.$store.dispatch(FETCH_OSAGO_VERIFICATION, this.transportFormData),
      this.$store.dispatch(FETCH_DRIVER_VERIFICATION, {dataType: 'phone', formData: this.transportFormData}),
      this.$store.dispatch(FETCH_DRIVER_VERIFICATION, {dataType: 'driver', formData: this.transportFormData}),
      this.$store.dispatch(FETCH_DRIVER_VERIFICATION, {dataType: 'fssp', formData: this.transportFormData}),
      this.$store.dispatch(FETCH_DRIVER_VERIFICATION, {
        dataType: 'trust_person',
        formData: this.transportFormData,
      }),
      this.$store.dispatch(FETCH_DRIVER_VERIFICATION, {
        dataType: 'passport_complex',
        formData: this.transportFormData,
      }),
      this.$store.dispatch(FETCH_DRIVER_VERIFICATION, {
        dataType: 'check_face',
        formData: this.transportFormData,
      }),
      this.$store.dispatch(FETCH_REPUTATION_VERIFICATION, this.transportFormData.inn),
      this.$store.dispatch(FETCH_PARTICIPATION_VERIFICATION, this.transportFormData),
    ]);
    this.dataRecieved = true;
    this.allFieldsNotEmpty = Object.keys(this.transportFormData).filter((key) => {
      return ['autoGos', 'autoVin', 'trailerGos', 'trailerVin']
          .includes(key) && this.transportFormData[key];
    }).length > 0 && Object.keys(this.transportFormData).filter((key) => {
      return ['ogrn', 'inn', 'externalId']
          .includes(key) && this.transportFormData[key];
    }).length > 0 && Object.keys(this.transportFormData).filter((key) => {
      return ['lastName', 'firstName', 'birthDate', 'passportNumber']
          .includes(key) && this.transportFormData[key];
    }).length > 0;
  }

  private async onSubmitPassport() {
    const file: any = this.transportFormData.photo;
    this.passportScan = file;
    if (!file) {
      return;
    }
    await this.$store.dispatch(UPLOAD_PASSPORT_SCAN, this.passportScan);
    if (this.recognizeDriverData.data) {
      ['lastName', 'firstName', 'midName', 'birthDate', 'passportNumber', 'passportDate', 'photo']
          .map((item: string) => {
            if (this.recognizeDriverData.data[item]) {
              this.transportFormData[item] = this.recognizeDriverData.data[item];
            }
          });
    } else if (this.recognizeDriverData.error) {
      this.passportError = true;
      this.passportStatus = 'Не распознан';
    }
  }

  private async onSubmitAtiSU() {
    await this.$store.dispatch(FETCH_ATI_SU_ID, this.transportFormData.inn);
  }

  get formPassed() {
    const readyToVerify = [];
    if (Object.keys(this.transportFormData).filter((key: any) => {
      return [
        'autoGos', 'autoVin', 'trailerGos', 'trailerVin', 'ogrn', 'inn', 'externalId',
      ].includes(key) && String(this.transportFormData[key]).length;
    }).length) {
      readyToVerify.push('auto');
    }
    if (this.transportFormData.drivingLicenseNumber) {
      readyToVerify.push('osago');
    }
    if (this.transportFormData.photo) {
      readyToVerify.push('photo');
    }
    if (Object.keys(this.transportFormData).filter((key: any) => {
      return [
        'lastName', 'firstName', 'midName', 'birthDate', 'passportNumber', 'passportDate',
      ].includes(key) && String(this.transportFormData[key]).length;
    }).length === 6) {
      readyToVerify.push('passport_complex');
    }
    if (Object.keys(this.transportFormData).filter((key: any) => {
      return [
        'lastName', 'firstName', 'midName', 'birthDate',
      ].includes(key) && String(this.transportFormData[key]).length;
    }).length === 4) {
      readyToVerify.push('fssp');
    }
    if (Object.keys(this.transportFormData).filter((key: any) => {
      return [
        'lastName', 'firstName', 'birthDate', 'passportNumber', 'phone',
      ].includes(key) && String(this.transportFormData[key]).length;
    }).length === 5) {
      readyToVerify.push('phone');
    }
    if (this.transportFormData.drivingLicenseNumber && this.transportFormData.drivingLicenseDate) {
      readyToVerify.push('driver');
    }
    if (Object.keys(this.transportFormData).filter((key: any) => {
      return [
        'lastName', 'firstName', 'midName', 'passportNumber',
      ].includes(key) && String(this.transportFormData[key]).length;
    }).length === 4) {
      readyToVerify.push('participation');
    }
    return readyToVerify;
  }

  private phoneFormat(value: string) {
    let newVal = value.replace(/\D/g, '');
    if (newVal.length > 10) {
      newVal = newVal.slice(0, 10);
    }
    const formattedVal = [];
    if (newVal.length > 3) {
      const codeCharPos = 3;
      formattedVal.push(newVal.substring(0, codeCharPos));
      formattedVal.push(newVal.substring(codeCharPos, codeCharPos + 3));
      formattedVal.push(newVal.substring(codeCharPos + 3, codeCharPos + 5));
      formattedVal.push(newVal.substring(codeCharPos + 5, codeCharPos + 7));
      newVal = formattedVal.join(' ').trim();
    }
    return newVal;
  }

  private passportFormat(value: string) {
    let newVal = value.replace(/\D/g, '');
    if (newVal.length > 10) {
      newVal = newVal.slice(0, 10);
    }
    const formattedVal = [];
    if (newVal.length > 4) {
      formattedVal.push(newVal.substring(0, 4));
      formattedVal.push(newVal.substring(4, 10));
      newVal = formattedVal.join(' ').trim();
    }
    return newVal;
  }

  private driverLicenseFormat(value: string, event: any) {
    return value.replace(/[^0-9а-яА-Я]/g, '');
  }

  private autoNumFormat(value: string, event: any) {
    const newVal = value.replace(/[^0-9а-яА-Я]| /g, '');
    return `${newVal.substring(0, 1)} ${newVal.substring(1, 4)} ${newVal.substring(4, 6)} ${newVal.substring(6)}`
        .trim();
  }

  private trailerNumFormat(value: string, event: any) {
    const newVal = value.replace(/[^0-9а-яА-Я]| /g, '');
    return `${newVal.substring(0, 2)} ${newVal.substring(2, 6)} ${newVal.substring(6)}`;
  }

  private externalIdFormat(value: string) {
    return value.replace(/[^0-9]/g, '');
  }

  private vinFormat(value: string, event: any) {
    return value.replace(/[^0-9a-zA-Z]/g, '');
  }

  private nameFormat(value: string, event: any) {
    return value.replace(/[0-9a-zA-Z]/g, '');
  }

  private async fillImageURL(event: any) {
    const file: any = event.target.files.length ? event.target.files![0] : '';
    if (file.type === 'application/pdf') {
      const formData = new FormData();
      formData.append('file',  file,  file.name);
      let resp: any = null;
      try {
        resp = await ApiService.postFile(`/impact/verification/convert_pdf_to_image`, formData);
      } catch (e) {
        return;
      }
      const base64Image = resp.data.file;
      this.photoPreviewData = `data:image/png;base64,${base64Image}`;
      this.transportFormData.photo = getFileFromBase64(base64Image, file.name);
    } else {
      this.transportFormData.photo = file;
      // const photoInput: any = this.$refs.photo;
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.photoPreviewData = event.target.result;
      };
      reader.readAsDataURL(file); // convert to base64 string
    }
  }

  private removePhoto() {
    this.transportFormData.photo = '';
    this.photoPreviewData = '';
    (this.$refs.uploadphoto as HTMLInputElement).value = '';
    this.$store.commit(CLEAR_CHECK_FACE);
  }

  private convertDate(data: any) {
    return convertDate(data);
  }

  private showIncident(incidentId: number) {
    const incidentView: any = this.$refs.incidentView;
    incidentView.loadIncident(incidentId);
    this.showIncidentView = true;
  }

  private async fetchTickets() {
    if (!this.ticketsCount) {
      await this.$store.dispatch(FETCH_VERIFICATION_TICKETS);
    }
  }

  private async showVerificationHistory() {
    this.showHistory = !this.showHistory;
  }

  private mounted() {
    this.$store.commit(CLEAR_VERIFICATION_HISTORY);
    if (this.transportFormData.photo) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.photoPreviewData = event.target.result;
      };
      reader.readAsDataURL(this.transportFormData.photo); // convert to base64 string
    }
  }

  private isCheckDisabled(checkType: number) {
    return (this.currentUser.disabledChecks & Math.pow(2, checkType)) > 0;
  }

  private canCheckPassport() {
    return !(this.isCheckDisabled(CHECK_FSSP) && this.isCheckDisabled(CHECK_PASSPORT_COMPLEX) &&
        this.isCheckDisabled(CHECK_PHONE) && this.isCheckDisabled(CHECK_TRUST_PERSON));
  }

  private formatReputationDate(reputationDate: any) {
    if (!(reputationDate === null) && reputationDate.length) {
      return reputationDate.substring(8, 10) + '.' + reputationDate.substring(5, 7) + '.' +
          reputationDate.substring(0, 4);
    } else {
      return '';
    }
  }

  private getReputationDateClass(reputationDate: any) {
    if (!(reputationDate === null) && !reputationDate.length) {
      return '';
    }
    const diff = Math.abs(Date.now() - new Date(reputationDate).getTime());
    const age = Math.floor((diff / (1000 * 3600 * 24)) / 365);
    if (age >= 3) {
      return 'success';
    } else if (age >= 1) {
      return 'warning';
    } else {
      return 'alert';
    }
  }

  private getReputationAmountClass(amount: number) {
    if (amount > 500000) {
      return 'alert';
    } else {
      return '';
    }
  }

  /** Отображение системы налогообложения. */
  private renderTaxationType(taxationType: string) {
    if (taxationType === 'Simplified') {
      return 'УСН';
    } else if (taxationType === 'ImputedIncome') {
      return 'ЕНВД';
    } else {
      return taxationType;
    }
  }

  /** Отображение значения из РСМП. */
  private renderRSMP(rsmpValue: string) {
    if (rsmpValue === 'MicroSizeOrganization') {
      return 'Микропредприятие';
    } else if (rsmpValue === 'SmallSizeOrganization') {
      return 'Малое предприятие';
    } else if (rsmpValue === 'MediumSizeOrganization') {
      return 'Среднее предприятие';
    } else {
      return rsmpValue;
    }
  }

  /** Показать карточку фирмы ati.su. */

  private openAtiSUInfo(atiSuId: number) {
    this.modalAtiSuId = atiSuId;
  }

  private closeAtiSUInfo() {
    this.modalAtiSuId = 0;
  }

  private toggleForeignDocuments() {
    this.foreignDocuments = !this.foreignDocuments;
  }
}
