
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {convertDate} from '@/common/helpers';
import ApiService from '@/common/api.service';
import saveAs from 'file-saver';
import Spinner from '@/components/Spinner.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { FETCH_VERIFICATION_TICKETS } from '@/store/actions.type';


@Component({
  components: {Spinner, InfiniteLoading},
  computed: mapGetters([
    'tickets',
    'ticketsCount',
    'currentUser',
    'isLoading',
  ]),
})


export default class VerificationHistory extends Vue {

  public tickets!: object[];
  public ticketsCount!: number;

  private page: number = 2;
  private infiniteId: number = +new Date();

  private searchQuery: any = '';

  private async mounted() {
    await this.searchHistory();
  }

  private setListedTicket(item: any, index: number) {
    const ticketDate: any = item.time_created ?
        `${convertDate(item.time_created)}` :
        this.$t('strings.undefined');
    const lName: any = item.last_name ? (item.last_name + ' ') : this.$t('strings.undefined');
    const fName: any = item.first_name ? (item.first_name[0] + '.') : '';
    const mName: any = item.mid_name ? (item.mid_name[0] + '.') : '';
    const driverIncidents: any = (item.verification_check_face && item.verification_check_face.matches) ?
        item.verification_check_face.matches.length :
        0;
    const vechileIncidents: any = (item.verification_risk && item.verification_risk.list) ?
        item.verification_risk.list.length : 0;
    const photo: any = item.photo_thumbnail ? 'data:image/jpeg;base64,' + item.photo_thumbnail : '';

    let scoreResult = this.$t('strings.low');
    if (item.verification_phone && item.verification_fss && item.verification_trust_person) {
      if ((item.verification_phone && item.verification_phone.operationResult !== 'success') ||
          item.verification_fssp.data ||
          item.verification_trust_person.resultCode === 0) {
        scoreResult = this.$t('strings.mid');
      }
    }
    if (item.verification_check_face && item.verification_passport_complex && item.verification_driver) {
      if (item.verification_check_face.matches ||
          item.verification_passport_complex.score !== 100 ||
          item.verification_driver.resultCode !== 0) {
        scoreResult = this.$t('strings.high');
      }
    }

    let scoreClass = 'success';
    if (scoreResult === this.$t('strings.mid')) {
      scoreClass = 'warning';
    } else if (scoreResult === this.$t('strings.high')) {
      scoreClass = 'alert';
    }


    const ticket: any = {
      index,
      id: item.id,
      time_created: ticketDate,
      full_name: lName + fName + mName,
      driver_incidents: driverIncidents,
      vechile_incidents: vechileIncidents,
      photo_base64: photo,
      photo_img: item.photo,
      score: scoreResult,
      score_class: scoreClass,
    };
    return ticket;
  }

  get formattedTickets() {
    return this.tickets.map((item: any, index: number) => {
      return this.setListedTicket(item, index);
    });
  }

  private async searchHistory() {
    this.page = 2;
    await this.$store.dispatch(FETCH_VERIFICATION_TICKETS, { page: 1, s: this.searchQuery });
    this.infiniteId += 1;
  }

  private async infiniteHandler($state: any) {
    if (this.tickets.length !== this.ticketsCount) {
      await this.$store.dispatch(FETCH_VERIFICATION_TICKETS, { page: this.page, s: this.searchQuery });
      $state.loaded();
      this.page += 1;
    } else {
      $state.complete();
    }
  }


  private hideVerificationHistory() {
    this.$emit('hide-verification-history', true);
  }

  private clearForm() {
    this.$emit('hide-verification-history', true);
    this.$emit('clear-form');
  }

  private async fetchTicketPdf(id: any) {
    const resp: any = await ApiService.getFile('/impact/verification_ticket/get_ticket_verification_pdf', {ticket: id});
    const contentDisposition = resp.headers['content-disposition'];
    let fileName = 'vetting.pdf';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }
    saveAs(resp.data, fileName);
  }


}
